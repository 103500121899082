import store from "./Store";

const BASE_URL = "https://wt-13aebf4eeaa9913542725d4a90e4d49e-0.run.webtask.io/meetupfinder/meetups";

function processData(data) {
  store.updateGlobalState({isLoading: false});

  
  store.updateGlobalState({meetups: data});

}

function fetchMeetups() {
  store.updateGlobalState({isLoading: true});

  let state = store.getGlobalState();
  let args;

  if (state.latlng.lat && state.latlng.lng) {
    args = `lat=${state.latlng.lat}&lon=${state.latlng.lng}`;
  } else {
    args = `location=${state.city}`;
  }

  args += `&radius=${state.radius}`;

  let url = `${BASE_URL}?${args}`;

  return fetch(url).then(res => res.json()).then(data => processData(data));
}

export {
  fetchMeetups
}